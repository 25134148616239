import { FunctionComponent } from "react";
import { useLocation } from "react-router-dom";
import QRCode from "react-qr-code";

interface Props {}

const Thankyou: FunctionComponent<Props> | any = () => {
    const { state }: any = useLocation();
    const { email, background } = state;

    if (state) {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12 col-md-12">
                        <div className="content" style={{ textAlign: "center", padding: "100px 0 200px" }}>
                            <img src="assets/images/img-tick.png" alt="tick" className="tickImg" />
                            <h1 style={{ marginBottom: "30px" }}>Thank You!</h1>

                            <br />
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <div
                                    style={{
                                        borderRadius: "15px",
                                        boxShadow: "1px 1px 10px 3px rgb(155 155 155 / 52%)",

                                        padding: "25px 50px 30px",
                                    }}>
                                    <p
                                        style={{
                                            fontWeight: "600",
                                        }}>
                                        Scan your QR code at the booth
                                    </p>

                                    <div
                                        style={{
                                            height: "auto",
                                            margin: "0 auto",
                                            // maxWidth: 64,
                                            width: "100%",
                                        }}>
                                        <QRCode
                                            size={256}
                                            style={{ height: "auto", maxWidth: "250px", width: "250px" }}
                                            value={`https://form.sobeysdatacapture.ca/?c1=${background}+start&autoemail=${email}`}
                                            viewBox={`0 0 256 256`}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default Thankyou;
